import React from "react";
import PropTypes from "prop-types";

import ResponseService from "../../services/response.service";
import translate from "../../translations";

export default class SuccessPage extends React.Component {
  componentDidMount() {
    this.rs = new ResponseService(this.props.response.tool.id);
  }

  render() {
    return (
      <>
        <div className="row g-0">
          <div className="col-12 col-md-6 offset-md-3 text-center">
            <h4 className="text-primary mb-4">
              {this.props.response.tool.name} -{" "}
              {translate("TextSelfAssessmentReport")} Ref{" "}
              {this.props.response.reference}
            </h4>
            <p className="text-black fw-bold">
              {translate(
                "SuccessPageThankYou",
                this.props.response.email_address
              )}
            </p>

            <p className="text-black">{translate("SuccessPageCheckEmail")}</p>

            <p>
              {translate("SuccessPagePleaseContact")}{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`mailto:${this.props.response.tool.contact_email}`}
              >
                {this.props.response.tool.contact_email}
              </a>
            </p>
          </div>
        </div>
      </>
    );
  }
}

SuccessPage.propTypes = {
  tool: PropTypes.object,
  userDetails: PropTypes.object,
  response: PropTypes.object,
};
