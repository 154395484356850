const STRINGS = {
  TextSelfAssessmentTool: {
    en: "Assessment Tool",
    de: "Selbstbewertungstool",
  },
  TextSelfAssessmentReport: {
    en: "Assessment Report",
    de: "Ergebnis Eigenbewertung Reifegrad",
  },
  StartSelfAssessmentButton: {
    en: "Start your Assessment",
    de: "Starten Sie Ihre Selbsteinschätzung",
  },
  TextQuestion: {
    en: "Question",
    de: "Frage",
  },
  TextPrevious: {
    en: "Previous",
    de: "Vorherige",
  },
  TextNext: {
    en: "Next",
    de: "Nächste",
  },
  TextSubmit: {
    en: "Submit",
    de: "Einreichen",
  },
  TextBack: {
    en: "Back",
    de: "Zurück",
  },
  TextContinue: {
    en: "Continue",
    de: "Fortsetzen",
  },
  AnswerPageTitle: {
    en: "Your Answers",
    de: "Deine Antworten",
  },
  AnswerPageDescription: {
    en: "We will estimate your maturity position based on your answers below. To edit any of these, please use the back button below.",
    de: "Wir schätzen Ihre Reifeposition anhand Ihrer Antworten unten. Um eines davon zu bearbeiten, verwenden Sie bitte die Button unten.",
  },
  UserDetailsPrompt: {
    en: "Please complete your details so that we can email your",
    de: "Bitte vervollständigen Sie Ihre Daten, damit wir Ihnen eine E-Mail senden können",
  },
  UserDetailsFormTitle: {
    en: "Your Details",
    de: "Deine Details",
  },
  TextFirstName: {
    en: "First Name",
    de: "Vorname",
  },
  TextLastName: {
    en: "Last Name",
    de: "Nachname",
  },
  TextJobTitle: {
    en: "Job Title",
    de: "Berufsbezeichnung",
  },
  TextCompany: {
    en: "Company",
    de: "Begleitung",
  },
  TextCountry: {
    en: "Country",
    de: "Land",
  },
  TextEmailAddress: {
    en: "Email Address",
    de: "E-Mail-Addresse",
  },
  TextConfirmEmail: {
    en: "Confirm Email",
    de: "Bestätigungs-E-Mail",
  },
  TextTelephone: {
    en: "Telephone",
    de: "Telefon",
  },
  UserDetailsAcquistionChannel: {
    en: "How did you hear about Oliver Wight",
    de: "Wie haben Sie von Oliver Wight gehört?",
  },
  TextPleaseSpecify: {
    en: "Please specify",
    de: "Bitte angeben",
  },
  TextTheNextStep: {
    en: "The Next Step",
    de: "Der nächste Schritt",
  },
  TextPrivacyPolicy: {
    en: "Privacy Policy",
    de: "Datenschutz-Bestimmungen",
  },
  TextSendReport: {
    en: "Send Report",
    de: "Bericht senden",
  },
  UserDetailsContactConsentIntroduction: {
    en: `By submitting this form, you agree that we may use the information
    to contact you about your report. We do not share this data with third parties.`,
    de: `Mit dem Absenden dieses Formulars stimmen Sie zu, dass wir die Informationen verwenden dürfen
    um Sie bezüglich Ihres Berichts zu kontaktieren. Wir geben diese Daten nicht an Dritte weiter.`,
  },
  UserDetailsContactConsent: {
    en: `We also want to keep you up to date on our products, services and
    thought leadership that you may be interested in.
    We ensure that all our communications are relevant and timely. If you
    change your mind, you can unsubscribe from these emails anytime
    by clicking the unsubscribe link at the bottom of emails or by emailing`,
    de: `Auch über unsere Produkte möchten wir Sie auf dem Laufenden halten,
    Dienstleistungen und Vordenker, von denen wir denken, dass Sie sie sein könnten
    interessiert. Wir stellen sicher, dass alle unsere Mitteilungen sind
    relevant und aktuell, aber wenn Sie jemals Ihre Meinung ändern, Sie
    Sie können diese E-Mails jederzeit abbestellen, indem Sie auf klicken
    Abbestellen-Link am Ende von E-Mails oder per E-Mail`,
  },
  UserDetailsPersonalData: {
    en: "To find out more about how we use your data, please see our",
    de: "Um mehr darüber zu erfahren, wie wir Ihre personenbezogenen Daten verwenden, lesen Sie bitte unsere",
  },
  SuccessPageThankYou: {
    en: "Thank you for submitting your details, a report has now been sent to {} based on your answers.",
    de: "Vielen Dank, dass Sie Ihre Details eingereicht haben. Basierend auf Ihren Antworten wurde jetzt ein Bericht an {} gesendet.",
  },
  SuccessPageCheckEmail: {
    en: "If the email is not in your inbox please check your spam or junk folder alternatively contact us and we will be happy to forward it to you.",
    de: `Wenn sich die E -Mail nicht in Ihrem Posteingang befindet,
    überprüfen Sie bitte Ihren Spam- oder Junk -Ordner. Kontaktieren
    Sie uns alternativ und wir werden ihn gerne an Sie weiterleiten.`,
  },
  SuccessPagePleaseContact: {
    en: "If you would like a more precise diagnostic, and to understand the opportunities to improve, please contact:",
    de: `Wenn Sie eine genauere Diagnose wünschen und die Möglichkeiten
    zur Verbesserung verstehen möchten, wenden Sie sich bitte an:`,
  },
  TextSearchEngine: {
    en: "Search Engine",
    de: "Suchmaschine",
  },
  TextWordOfMouth: {
    en: "Word of Mouth",
    de: "Mundwort",
  },
  TextSocialMedia: {
    en: "Social Media",
    de: "Sozialen Medien",
  },
  TextPressOrMagazine: {
    en: "Press or Magazine",
    de: "Presse oder Magazin",
  },
  TextWorkedWithBefore: {
    en: "Worked with Before",
    de: "Mit vorher zusammengearbeitet",
  },
  TextOther: {
    en: "Other",
    de: "Andere",
  },
  ErrorFieldRequired: {
    en: "This field is required",
    de: "Dieses Feld ist erforderlich",
  },
  ErrorFieldEmail: {
    en: "Please enter a valid email address",
    de: "Bitte geben Sie eine gültige E-Mail-Adresse ein",
  },
  ErrorFieldSameAs: {
    en: "Field does not match {}",
    de: "Feld stimmt nicht überein {}",
  },
  ErrorFieldTelephone: {
    en: "Please enter a valid telephone number",
    de: "Bitte geben Sie eine gültige Telefonnummer ein",
  },
};

function _format(str, ...args) {
  let i = 0;
  return str.replace(/{}/g, function () {
    return typeof args[i] !== "undefined" ? args[i++] : "";
  });
}

export default function translate(string, ...args) {
  const language = sessionStorage.getItem("toolLanguage");
  try {
    return (
      _format(STRINGS[string][language], args) ||
      `No ${language} translation provided for ${string}`
    );
  } catch (error) {
    return `No string table entry for ${string}`;
  }
}
