import React from "react";
import PropTypes from "prop-types";
import styles from "./user-details-page.module.scss";

import translate from "../../translations";
import UserDetailsForm from "./user-details-form";

export default function UserDetailsPage(props) {
  const tool = props.tool;

  return (
    <div>
      <div className="text-center mb-5">
        <h4 className="text-primary">
          {translate("TextSelfAssessmentReport")}
        </h4>
        <h5 className="fw-bold">
          <div>{translate("UserDetailsPrompt")}</div>
          <div>
            {tool.name} {translate("TextSelfAssessmentReport")}
          </div>
        </h5>
      </div>
      <div>
        <div className="bg-neutral py-4 px-lg-5 px-4">
          <div className="mb-5">
            <h5 className={`${styles.title}`}>
              {translate("UserDetailsFormTitle")}
            </h5>
          </div>
          <UserDetailsForm
            countryList={props.countryList}
            country={props.country}
            onSubmit={(values) => props.onSubmit(values)}
          ></UserDetailsForm>
        </div>
      </div>
    </div>
  );
}

UserDetailsPage.propTypes = {
  onSubmit: PropTypes.func,
  tool: PropTypes.object,
  country: PropTypes.string,
  countryList: PropTypes.array,
};
