import React from "react";
import PropTypes from "prop-types";

import Answer from "./answer/";

import translate from "../../translations";

export default class SummaryPage extends React.Component {
  constructor(props) {
    super();

    this.goBack = this.goBack.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  goBack(e) {
    this.props.onNavigateBack(e);
  }

  onSubmit(e) {
    this.props.onSubmit(e);
  }

  render() {
    const answers = this.props.answers;
    return (
      <div>
        <div className="text-center mb-4">
          <h4>{translate("AnswerPageTitle")}</h4>
          <p>{translate("AnswerPageDescription")}</p>
        </div>
        {answers.map((answer, index) => {
          return (
            <Answer
              key={index}
              answer={answer}
              questionNumber={index + 1}
            ></Answer>
          );
        })}
        <div className="d-flex mt-4">
          <button
            className="btn btn-outline-primary me-3"
            onClick={this.goBack}
          >
            {translate("TextBack")}
          </button>
          <button className="btn btn-primary" onClick={this.onSubmit}>
            {translate("TextContinue")}
          </button>
        </div>
      </div>
    );
  }
}

SummaryPage.propTypes = {
  answers: PropTypes.array,
  onNavigateBack: PropTypes.func,
  onSubmit: PropTypes.func,
};
