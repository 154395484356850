import React from "react";
import PropTypes from "prop-types";
import s from "./start-page.module.scss";

import translate from "../../translations";

export default class StartPage extends React.Component {
  constructor(props) {
    super();
    this.tool = props.tool;
    this.state = {};
    this.start = this.start.bind(this);
  }

  start() {
    this.props.onStart();
  }

  render() {
    return (
      <div className={`row g-0 ${s.startPageContainer}`}>
        <div
          className="col-12 col-md-8 offset-md-2 text-primary text-center"
          dangerouslySetInnerHTML={{ __html: this.tool.summary }}
        ></div>
        <div
          className="col-12 col-md-8 offset-md-2 py-3 mb-4"
          dangerouslySetInnerHTML={{ __html: this.tool.description }}
        ></div>
        <div className="col-12 text-center">
          <button onClick={this.start} className="btn btn-primary">
            {translate("StartSelfAssessmentButton")}
          </button>
        </div>
      </div>
    );
  }
}

StartPage.propTypes = {
  onStart: PropTypes.func,
  tool: PropTypes.object,
};
